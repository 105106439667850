import { BehaviorSubject, Observable, Subject } from "rxjs";
import TinyAnimate from 'TinyAnimate';
import { bindTo, Keys, StartAndStoppable } from "./keys";
import { Reactive } from "./observables";
import { requireThat } from "./tools";


export namespace Fiat {
    export function body() { return wrap(document.body) }
    export function wrap(element: HTMLElement) { return new Node(element.tagName, element) }
    export function node(tagName: string) { return new Node(tagName) }
    export function br() { return new Node("BR") }
    export function div(): Node { return new Node("div") }
    export function image(path: string | null = null): Node { return new Node("img").attr("src", path).notSelectable() }
    export function text(string: string): Node { return div().text(string).display("inline-block") }
    export function property<T>(initial: T, compare?: (a: T, b: T) => boolean): Subject<T> {
        const subject = new Subject<T>()
        subject.next(initial)
        return subject
    }
}


function startDomObservations() {
    if (!(document as any).fDomObserver) {

        console.log("registering mutation observer (ts)")

        const onAttached = function (node) {
            if (node.fOnAttached) node.fOnAttached.forEach(callback => callback())
            if (node.childNodes) node.childNodes.forEach(onAttached)
        }

        const onDetached = function (node) {
            if (node.childNodes) node.childNodes.forEach(onDetached)
            if (node.fOnDetached) node.fOnDetached.forEach(callback => callback())
            if (node === document) {
                domObserver.disconnect();
                (document as any).fDomObserver = null
            }
        }

        const domObserver = new MutationObserver(function (mutations) {
            domObserver.takeRecords()
            for (const record of mutations) {
                record.addedNodes.forEach(onAttached)
                record.removedNodes.forEach(onDetached)
            }
        })

        domObserver.observe(document.body, { subtree: true, childList: true });

        (document as any).fDomObserver = domObserver
        document.onclose = function () {
            domObserver.disconnect();
            (document as any).fDomObserver = null
            document.onclose = null
        }

    }
}

startDomObservations()


class Node {

    private tagName: string
    private _element: HTMLElement

    get element(): HTMLElement {
        return this._element
    }

    constructor(tagName: string, element: HTMLElement | null = null) {
        this.tagName = tagName
        requireThat(element == null || element.tagName == tagName)
        this._element = element || document.createElement(this.tagName)
    }

    appendToBody(): Node {
        document.body.appendChild(this._element)
        return this
    }

    appendTo(target: Node): Node {
        target._element.appendChild(this.element)
        return this
    }

    remove(): Node {
        this._element.remove()
        return this
    }

    text(text: string): Node {
        this._element.textContent = text
        return this
    }

    getText(): string { return this._element.textContent ?? "" }

    style(key: string, value: any): Node {
        this._element.style[key] = (value !== null) && value.toString()
        return this
    }

    clearTransformation() {
        const style = this._element.style as any
        style.transform = ''
        style.msTransform = ''
        style.webkitTransform = ''
        return this
    }

    readTransformation(key: string): string[] {
        const style = this._element.style as any
        const transformation = style.transform || style.msTransform || style.webkitTransform || ""
        const parts = transformation.split(/\)( )*/).map(x => x && x.trim())
        const myPrefix = key + '('
        const part = parts.find(part => part.startsWith(myPrefix))
        if (!part) return []
        const parameters = part.substring(key.length + 1).split(",")
        return parameters.map(x => x.trim())
    }

    translateX(translation: Measure) {
        this._element.style.transform = `translateX(${translation})`
        return this
    }
    translateY(translation: Measure) {
        this._element.style.transform = `translateY(${translation})`
        return this
    }
    translate(x: Measure, y: Measure): Node {
        this._element.style.transform = `translate(${x}, ${y})`
        return this
    }

    append(...children: (Node | null)[]): Node {
        for (let child of children) if (child) this._element.appendChild(child._element)
        return this
    }

    focus(): Node {
        this.element.focus()
        return this
    }

    blur(): Node {
        this.element.blur()
        return this
    }

    clear(): Node {
        while (this._element.lastChild) this._element.removeChild(this._element.lastChild)
        return this
    }

    on<T extends Event>(eventType: string, handler: (event: T) => void) {
        this._element.addEventListener(eventType, handler as any)
    }

    off<T extends Event>(eventType: string, handler: (event: T) => void) {
        this._element.removeEventListener(eventType, handler as any)
    }

    keys(
        predicate: (event: KeyboardEvent) => boolean,
        handler: (event: KeyboardEvent) => void,
        preventsPropagation: boolean = true
    ): StartAndStoppable {
        const listener = Keys.on(predicate, handler, preventsPropagation)
        bindTo(listener, this.attached())
        return listener
    }

    attr(key: string, value: string | boolean | number | null): Node {
        if (value == null || value == false) this._element.removeAttribute(key)
        else this._element.setAttribute(key, value.toString())
        return this
    }
    getAttr(key: string): string | null { return this._element.getAttribute(key) }

    display(display: string | null): Node { return this.style("display", display) }
    noBorder(): Node { return this.style("border", "none") }
    noOutline(): Node { return this.style("outline", "none") }
    border(measure: Measure, color: Rgb, type: string = "solid"): Node { return this.style("border", `${measure.toString()} ${color.toString()} ${type}`) }
    borderLeft(measure: Measure, color: Rgb, type: string = "solid"): Node { return this.style("borderLeft", `${measure.toString()} ${color.toString()} ${type}`) }
    borderRight(measure: Measure, color: Rgb, type: string = "solid"): Node { return this.style("borderRight", `${measure.toString()} ${color.toString()} ${type}`) }
    borderTop(measure: Measure, color: Rgb, type: string = "solid"): Node { return this.style("borderTop", `${measure.toString()} ${color.toString()} ${type}`) }
    borderBottom(measure: Measure, color: Rgb, type: string = "solid"): Node { return this.style("borderBottom", `${measure.toString()} ${color.toString()} ${type}`) }
    left(left: Measure | null): Node { return this.style("left", left) }
    top(top: Measure | null): Node { return this.style("top", top) }
    right(right: Measure | null): Node { return this.style("right", right) }
    bottom(bottom: Measure | null): Node { return this.style("bottom", bottom) }
    backgroundColor(color: Rgb | null): Node { return this.style("backgroundColor", color) }
    noBackground(): Node { return this.style("background", "none") }
    color(color: Rgb | null): Node { return this.style("color", color) }
    opacity(opacity: Number | null): Node { return this.style("opacity", opacity) }
    noBreak(): Node { return this.style("whiteSpace", "nowrap") }
    bold() { return this.style("fontWeight", "bold") }
    borderRadius(radius: Measure | null): Node { return this.style("borderRadius", radius) }
    padding(padding: Measure | null): Node { return this.style("padding", padding) }
    paddingLeft(padding: Measure | null): Node { return this.style("paddingLeft", padding) }
    paddingTop(padding: Measure | null): Node { return this.style("paddingTop", padding) }
    paddingRight(padding: Measure | null): Node { return this.style("paddingRight", padding) }
    paddingBottom(padding: Measure | null): Node { return this.style("paddingBottom", padding) }
    verticalPadding(padding: Measure): Node { return this.paddingTop(padding).paddingBottom(padding) }
    horizontalPadding(padding: Measure): Node { return this.paddingLeft(padding).paddingRight(padding) }
    inlineBlock(): Node { this._element.style.display = 'inline-block'; return this }
    cursor(cursor: string): Node { this._element.style.cursor = cursor; return this }
    width(width: Measure | null): Node { return this.style("width", width) }
    height(height: Measure | null): Node { return this.style("height", height) }
    minWidth(width: Measure | null): Node { return this.style("minWidth", width) }
    minHeight(height: Measure | null): Node { return this.style("minHeight", height) }
    maxWidth(width: Measure | null): Node { return this.style("maxWidth", width) }
    maxHeight(height: Measure | null): Node { return this.style("maxHeight", height) }
    size(size: Measure | null): Node { return this.width(size).height(size) }
    marginLeft(margin: Measure | string | null): Node { return this.style("marginLeft", margin) }
    marginRight(margin: Measure | string | null): Node { return this.style("marginRight", margin) }
    rightAuto(): Node { this._element.style.marginRight = "auto"; return this; }
    marginTop(margin: Measure | string | null): Node { return this.style("marginTop", margin) }
    marginBottom(margin: Measure | string | null): Node { return this.style("marginBottom", margin) }
    horizontalMargin(margin: Measure | string): Node { return this.marginLeft(margin).marginRight(margin) }
    verticalMargin(margin: Measure): Node { return this.marginTop(margin).marginBottom(margin) }
    margin(margin: Measure | string | null): Node { return this.style("margin", margin) }
    overflow(overflow: string | null): Node { return this.style("overflow", overflow) }
    textAlign(align: string | null): Node { return this.style("textAlign", align) }
    absolute(): Node { return this.style("position", "absolute") }
    relative(): Node { return this.style("position", "relative") }
    fill() { return this.width(Measures.fill).height(Measures.fill) }
    cover() { return this.fill().left(Measures.zero).top(Measures.zero).absolute() }
    flexRow(): Node { return this.display("flex") }
    inlineFlexRow(): Node { return this.display("inline-flex") }
    justifyStart() { return this.style("justify-content", "flex-start") }
    justifyCenter() { return this.style("justify-content", "center") }
    justifyEnd() { return this.style("justify-content", "flex-end") }
    alignStart() { return this.style("align-items", "flex-start") }
    alignCenter() { return this.style("align-items", "center") }
    alignBaseline() { return this.style("align-items", "baseline") }
    alignEnd() { return this.style("align-items", "flex-end") }
    alignStretch() { return this.style("align-items", "stretch") }
    alignSelf(align: string) { return this.style("align-self", align) }
    flexColumn(): Node { return this.display("flex").style("flexDirection", "column") }
    inlineFlexColumn(): Node { return this.display("inline-flex").style("flexDirection", "column") }
    noWrap() { return this.style("whiteSpace", "nowrap") }
    grow(weight: number): Node { return this.style("flexGrow", weight) }
    shrink(weight: number): Node { return this.style("flexShrink", weight) }
    center(): Node { return this.absolute().left(Measures.zero).top(Measures.zero).right(Measures.zero).bottom(Measures.zero).margin('auto') }
    centerVertically() { return this.absolute().top(Measures.zero).bottom(Measures.zero).style("marginTop", "auto").style("marginBottom", "auto") }
    zIndex(zIndex: Number): Node { this._element.style.zIndex = zIndex.toString(); return this }
    exactSize(size: Measure) { return this.exactWidth(size).exactHeight(size) }
    mono() { return this.style("fontFamily", "monospace") }
    exactWidth(size: Measure) { return this.width(size).minWidth(size).maxWidth(size) }
    exactHeight(size: Measure) { return this.height(size).minHeight(size).maxHeight(size) }
    fontSize(size: Measure) { return this.style("fontSize", size.toString()) }
    focusable() { return this.attr("tabindex", 0).style("outline", "none") }
    disablePointerEvents() { return this.style("pointerEvents", "none") }
    enablePointerEvents() { return this.style("pointerEvents", "auto") }

    measure(): DOMRect { return this.element.getBoundingClientRect() }

    value(): string { return (<HTMLInputElement>this._element).value }
    checked(): boolean { return (<HTMLInputElement>this._element).checked }

    focusOnAttached(): Node { this.attached().subscribe(attached => { if (attached) this.focus() }); return this; }

    swallowClicks() { return this.clicked(() => { /*NOOP*/ }) }
    clicked(handler: (event: MouseEvent) => void, stopEvent: boolean = true): Node {
        this._element.onclick = (event) => { if (stopEvent) event.stop(); handler(event) }
        this.cursor("pointer")
        return this
    }

    notSelectable(): Node {
        const style: any = this._element.style
        style.webkitTapHighlightColor = "rgba(0,0,0,0)"
        style.webkitTouchCallout = "none"
        style.webkitUserSelect = "none"
        style.khtmlUserSelect = "none"
        style.mozUserSelect = "none"
        style.msUserSelect = "none"
        style.oUserSelect = "none"
        style.userSelect = "none"
        return this
    }

    onPan(callback: (MouseEvent) => void) {
        const start = (event: MouseEvent) => {
            window.addEventListener("mousemove", move)
            window.addEventListener("mouseup", stop)
        }
        const stop = (event: MouseEvent) => {
            window.removeEventListener("mousemove", move)
            window.removeEventListener("mouseup", stop)
        }
        const move = (event: MouseEvent) => {
            callback(event)
        }
        this.on("mousedown", start)
    }


    readStyle(key: string): string {
        const computed = window.getComputedStyle(this._element)[key]
        if (typeof (computed) === 'string' && computed.length) return computed
        return this._element.style[key]
    }

    private registerHandler(element, subject: BehaviorSubject<boolean>) {
        element.fOnAttached = element.fOnAttached || [];
        element.fOnAttached.push(function () { subject.next(true) })
        element.fOnDetached = element.fOnDetached || [];
        element.fOnDetached.push(function () { subject.next(false) })
    }

    private _attached: Observable<boolean> | null = null
    attached(): Observable<boolean> {
        if (!this._attached) {
            const subject = Reactive.subject(document.body.contains(this._element));
            this.registerHandler(this.element, subject)
            this._attached = subject.asObservable()
        }
        return this._attached
    }

    private _focused: Observable<boolean> | null = null
    focused(): Observable<boolean> {
        if (!this._focused) {
            const subject = Reactive.subject(document.activeElement == this.element)
            this.element.onfocus = () => { subject.next(true) }
            this.element.onblur = () => { subject.next(false) }
            this._focused = subject.asObservable()
        }
        return this._focused
    }

    bounds(): DOMRect { return this._element.getBoundingClientRect() }


    animateProperty(property: Property, specification: CssAnimationSpecification): Animation {
        let chosenUnit: Unit | undefined = specification.unit
        return animate({
            from: () => {
                const result = (specification.from) ? parse(specification.from) : property.read(this)
                chosenUnit = chosenUnit || unitOf(result)
                return measureToNumber(result)
            },
            to: () => {
                const result = parse(specification.to)
                chosenUnit = chosenUnit || unitOf(result)
                return measureToNumber(result)
            },
            update: (current: number) => {
                property.write(new Measure(current, chosenUnit ?? Unit.Px), this)
            },
            duration: specification.duration,
            easing: specification.easing,
            onDone: specification.onDone
        })
    }


    animate(property: string, specification: CssAnimationSpecification): Animation {
        const animationProperty: Property = {
            read: () => parseMeasure(this.readStyle(property)),
            write: (value) => this.style(property, value.value + value.unit)
        }
        return this.animateProperty(animationProperty, specification)
    }

}


function unitOf(measure): Unit | undefined {
    if (measure instanceof Measure) return measure.unit
    return undefined
}

export function animate(specification: AnimationSpecification): Animation {
    const animation = TinyAnimate.animate(
        specification.from(),
        specification.to(),
        (specification.duration != 0) ? (specification.duration || 300) : 0,
        specification.update,
        specification.easing || 'easeInOutQuart',
        specification.onDone
    )
    return { cancel: () => { TinyAnimate.cancel(animation); if (specification.onCanceled) specification.onCanceled() } }
}

export interface AnimationSpecification {
    from(): number
    to(): number
    update: (current: number) => void
    duration?: number
    onDone?: () => void,
    onCanceled?: () => void,
    easing?: string
}

type AnimationTarget = number | Measure | (() => (number | Measure))
function parse(target): Measure | number {
    if (target instanceof Measure) return target
    if (isNaN(target)) return parse(target())
    return target
}
function measureToNumber(measure: Measure | number | null) {
    return (measure instanceof Measure) ? measure.value : (measure as number)
}

export interface CssAnimationSpecification {
    from?: AnimationTarget
    to: AnimationTarget
    unit?: Unit
    duration?: number
    easing?: string
    onDone?: () => void,
    onCanceled?: () => void
}

export interface Animation {
    cancel(): void
}

class Rgb {
    _red: number
    _green: number
    _blue: number
    _alpha: number

    constructor(red: number, green: number, blue: number, alpha: number = 1.0) {
        this._red = red
        this._green = green
        this._blue = blue
        this._alpha = alpha
    }

    get red(): number { return this._red }
    get blue(): number { return this._blue }
    get green(): number { return this._green }

    toString(): string {
        return `rgba(${this._red}, ${this._green}, ${this._blue}, ${this._alpha})`
    }
}

enum Unit {
    Plain = '', Px = "px", Cm = "cm", Deg = "deg", Pc = "%"
}

function cmToPx(cms: number): number { return cms * 30 }
function cm(cms: number): Measure { return new Measure(cmToPx(cms), Unit.Px) }
function px(pxs: number): Measure { return new Measure(pxs, Unit.Px) }
function pc(pcs: number): Measure { return new Measure(pcs, Unit.Pc) }

class Measure {

    private _value: number
    readonly unit: Unit

    get value(): number { return this._value }

    constructor(value: number, unit: Unit) {
        this._value = value
        this.unit = unit
    }

    static parse(value: any): Measure {
        if (value instanceof Measure) return value
        if (typeof value == 'number') return new Measure(value as number, Unit.Plain)
        const parsed = parseFloat(value)
        Object.keys(Unit).forEach(key => {
            const unit = Unit[key]
            if (value.includes(unit)) return new Measure(parsed, unit)
        })
        throw `could not parse measure: ${value}`
    }

    toString(): string {
        return `${this._value}${this.unit}`
    }
}

export namespace Measures {
    export const zero = px(0)
    export const fill = pc(100)
}

interface Property {
    read(source: Node): Measure | null
    write(current: Measure, sink: Node)
}

export const Opacity: Property = {
    read: (source: Node) => new Measure(parseFloat(source.readStyle("opacity")), Unit.Plain),
    write: (opacity: Measure, sink: Node) => sink.style("opacity", opacity.value)
}

export function TranslationX(defaultUnit: Unit = Unit.Pc): Property {
    return {
        read: (source: Node) => {
            const single = source.readTransformation("translateX")
            if (single.length) return parseMeasure(single[0], defaultUnit)
            const multi = source.readTransformation("translate")
            if (multi.length) return parseMeasure(multi[0], defaultUnit)
            return new Measure(0, defaultUnit)
        },
        write: (value: Measure, sink: Node) => {
            sink.translateX(value)
        }
    }
}

export function TranslationY(defaultUnit: Unit): Property {
    return {
        read: (source: Node) => {
            const single = source.readTransformation("translateY")
            if (single.length) return parseMeasure(single[0], defaultUnit)
            const multi = source.readTransformation("translate")
            if (multi.length) return parseMeasure(multi[1], defaultUnit)
            return new Measure(0, defaultUnit)
        },
        write: (value: Measure, sink: Node) => sink.translateY(value)
    }
}

function parseMeasure(string: string, defaultUnit: Unit = Unit.Plain): Measure | null {
    if (!string) return null
    const parts = string.split(/(-?[0-9.]+)/).map(p => p.trim()).filter(p => p.length > 0)
    if (!parts.length) return null
    const value = parseFloat(parts[0])
    if (parts.length == 1) return new Measure(value, defaultUnit)
    const unit = parts[1].toLowerCase()
    return new Measure(value, unit as Unit)
}


export namespace Colors {

    export function parse(value: string): Rgb {
        if (value.startsWith("rgb")) {
            const components: number[] = value.replace(/[^0-9,.]/g, "").split(',').map(value => (parseFloat(value) || 0))
            return new Rgb(
                Math.round(components[0]),
                Math.round(components[1]),
                Math.round(components[2]),
                components.length > 3 && Math.round(components[3]) || 1.0
            )
        } else if (value.startsWith('#')) {
            const length = (value.length == 3) ? 1 : 2
            const red = parseInt(value.substr(1, length), 16)
            const green = parseInt(value.substr(1 + length, length), 16)
            const blue = parseInt(value.substr(1 + 2 * length, length), 16)
            return new Rgb(red, green, blue)
        }
        throw `color-format not supported: ${value}`
    }

    export function random(): Rgb {
        return new Rgb(
            Math.floor(Math.random() * 256),
            Math.floor(Math.random() * 256),
            Math.floor(Math.random() * 256)
        )
    }

    export const background = parse("#ffffff")
    export const bright = parse("#f3f3f3")
    export const dark = parse("#303030")
    export const signalBorder = parse("#FF9500")
    export const signalBackground = parse("#FFE0B5")
    export const focusBorder = parse("#7faef8")

}

export { Node, Rgb, Measure, cm, px, pc, cmToPx };


const defaultUnits = {
    opacity: Unit.Plain
}

function defaultUnitOf(property: string): Unit {
    const result = defaultUnits[property.toLowerCase()]
    if (result == undefined) return Unit.Px
    return result
}
