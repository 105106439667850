import { Fiat, Node, Colors, cm } from "./fiat";
import './extensions';

export function Button(text: string, handler: () => void): Node {

    const view = Fiat.div().backgroundColor(Colors.dark)
        .borderRadius(cm(0.1))
        .padding(cm(0.2))
        .fontSize(cm(0.6)).color(Colors.bright)
        .text(text)
        .focusable()
        .clicked(handler)

    view.focused().subscribe(focused => {
        if (focused) view.border(cm(0.1), Colors.focusBorder)
        else view.border(cm(0.1), Colors.background)
    })

    view.keys((event) => { return event.key == "Enter" }, (event) => { handler() })

    return view

}