import { Observable } from "rxjs";


export interface StartAndStoppable {
    start()
    stop()
}

export namespace Keys {

    interface Listener extends StartAndStoppable {
        preventsPropagation: boolean
        handles(event: KeyboardEvent): boolean
        handle(event: KeyboardEvent)
    }

    const listeners: Array<Listener> = []
    document.addEventListener("keydown", (event) => {
        let consumed = false
        for (const listener of listeners) {
            if (listener.handles(event)) {
                consumed = true
                listener.handle(event)
                if (listener.preventsPropagation) break
            }
        }
        if (consumed) event.stop()
    })

    export function on(
        predicate: (event: KeyboardEvent) => boolean,
        handler: (KeyboardEvent) => void,
        preventsPropagation: boolean = true
    ): StartAndStoppable {
        const listener: Listener = {
            preventsPropagation: preventsPropagation,
            handles: predicate,
            handle: handler,
            start: () => { listeners.push(listener) },
            stop: () => { listeners.remove(listener) }
        }
        return listener
    }

}

export function bindTo(startAndStoppable: StartAndStoppable, active: Observable<boolean>) {
    active.onUpdate(active => active ? startAndStoppable.start() : startAndStoppable.stop())
}
