import { cm, Colors, Fiat, Measures, Node, pc, px } from './commons/fiat';
import { Observable, Subject } from 'rxjs';
import { Reactive } from './commons/observables';
import { Button } from './commons/button';
import { http } from './commons/tools';



interface Page {
    view: Node
}

const headerSize = cm(0.7)
const messageSize = cm(0.6)

function Header(text: string) { return Fiat.text(text).fontSize(headerSize).maxWidth(cm(12)).textAlign("center").mono() }
function Message(text: string) { return Fiat.text(text).fontSize(messageSize).maxWidth(cm(12)).textAlign("center") }
function Body(header: Node, message: Node) { return Fiat.div().flexColumn().alignCenter().append(header.marginBottom(cm(0.4)), message) }

function showError(error: Error) {
    const header = Header("Ein Problem ist aufgetreten.")
    const message = Message("Bitte laden Sie die Seite neu und/oder kontaktieren Sie uns persönlich.")
    currentPage.next({ view: Body(header, message) })
    console.log(error)
}


function showDenied() {
    const header = Header("Ihre Anfrage wurde abgewiesen.")
    const message = Message("Sollte es sich hierbei um ein Missverständnis handeln, wenden Sie sich gerne persönlich an uns.")
    currentPage.next({ view: Body(header, message) })
}


function redirect() {
    const parameters = new URLSearchParams(window.location.search)
    const target = parameters.get("target") || window.location.href
    document.location.href = target
}

function startPollingFor(requestId: string) {
    const header = Header("Ihre Anfrage wird bearbeitet.")
    const message = Message("Bitte gedulden Sie sich - wir prüfen derweil Ihre Anfrage und melden uns bei Ihnen.")
    currentPage.next({ view: Body(header, message) })

    const poll = () => {
        window.setTimeout(() => {
            const encodedId = decodeURIComponent(requestId)
            http.getJson("/api/status?requestId=" + encodedId).then(status => {
                const allowed = status.allowed == true
                const pending = status.pending == true;
                (pending) ? poll() : allowed ? redirect() : showDenied()
            }).catch(error => showError(error))
        }, 1000)
    }

    poll()
}


function loginPage(): Page {

    const header = Header("Dieser Arbeitsplatz ist uns bisher unbekannt.")
    const message = Message("Wenn Sie uns Ihren Namen und/oder Kontaktdaten nennen, können Sie einen Zugang beantragen:")
    const identifier = Fiat.node("input").attr("type", "text")
        .noBorder().noOutline()
        .borderBottom(cm(0.05), Colors.dark)
        .textAlign("center").fontSize(cm(0.8)).mono()
        .focusOnAttached()

    const rememberMe = new function () {
        const id = "rememberme"
        this.checkbox = Fiat.node("input").attr("type", "checkbox").attr("id", id).attr("checked", true)
        const label = Fiat.text("Diesen Arbeitsplatz auf unbestimmte Zeit aktivieren.")
        this.container = Fiat.node("label").append(Fiat.div().flexRow().alignCenter().append(this.checkbox.marginRight(cm(0.3)), label)).attr("for", id).padding(cm(0.3))
    }()

    const button = Button("Aktivierung beantragen", () => {
        console.log(rememberMe.checkbox.checked())
        const informations = { identifier: identifier.value(), rememberMe: rememberMe.checkbox.checked() }
        http.post("/api/request", informations).then(answer => <string>answer.requestId).then(requestId => startPollingFor(requestId)).catch(error => console.error(error))
    })

    return {
        view: Fiat.div().flexColumn().alignCenter().append(
            Body(header, message).marginBottom(cm(1)),
            identifier.marginBottom(cm(0.3)),
            rememberMe.container.marginBottom(cm(1)),
            button
        )
    }
}

const currentPage = Reactive.subject<Page>(loginPage())

export function main() {
    currentPage.next(loginPage())
    const container = Fiat.div().fill().flexColumn().alignCenter().justifyCenter().appendToBody()
    currentPage.subscribe(page => container.clear().append(page.view))
}