import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, startWith, take } from 'rxjs/operators';

declare module 'rxjs' {

    interface BehaviorSubject<T> {
        push(element: any)
        pop()
        remove(element: any)
        toggle()
    }

    interface Observable<T> {
        onUpdate(callback: (value: T) => void, compare?: (a: T, b: T) => boolean): Subscription
        and<P>(other: Observable<P>, doLog?: boolean): Observable<boolean>
        or<P>(other: Observable<P>): Observable<boolean>
        not(): Observable<boolean>
        derive<P>(transformation: (value: T) => P): Observable<P>
        filterTrue(): Observable<void>
        filterFalse(): Observable<void>
        take(n: number): Observable<T>
        drop(n: number): Observable<T>
        startWith<P>(value: P): Observable<T | P>
    }
}

declare global {
    interface Event {
        stop()
    }
    interface Array<T> {
        remove(element: T)
    }
}

Event.prototype.stop = function () {
    this.stopPropagation()
    this.preventDefault()
}

Array.prototype.remove = function (element) {
    const index = this.indexOf(element)
    if (index < 0) return null
    this.splice(index, 1)
}

BehaviorSubject.prototype.derive = function <P>(transformation: (input: any) => P): Observable<P> {
    return this.pipe(map(transformation))
}

Observable.prototype.derive = function <P>(transformation: (input: any) => P): Observable<P> {
    return this.pipe(map(transformation))
}

BehaviorSubject.prototype.toggle = function () { this.next(!this.getValue()) }

BehaviorSubject.prototype.push = function (element: any) {
    return this.next([...this.getValue(), element])
}

BehaviorSubject.prototype.pop = function () {
    return this.next(this.getValue().slice(0, -1))
}

BehaviorSubject.prototype.remove = function (element: any) {
    this.next(this.getValue().filter(value => value !== element))
}

Observable.prototype.onUpdate = function (callback: (T: any) => void, compare?: (a: any, b: any) => boolean): Subscription {
    return this.pipe(distinctUntilChanged(compare)).subscribe(callback)
}

Observable.prototype.and = function <P>(other: Observable<P>, doLog: boolean = false): Observable<boolean> {
    return combineLatest([this, other]).pipe(map(values => {
        return values.every(value => value ? true : false)
    }))
}

Observable.prototype.or = function <P>(other: Observable<P>): Observable<boolean> {
    return combineLatest([this, other]).pipe(map(values => values.some(value => value ? true : false)))
}

Observable.prototype.not = function (): Observable<boolean> {
    return this.pipe(map(value => value ? false : true))
}

Observable.prototype.derive = function <P>(transformation: (T: any) => P): Observable<P> {
    return this.pipe(map(transformation))
}

Observable.prototype.filterTrue = function (): Observable<void> {
    return this.pipe(filter(x => x ? true : false))
}

Observable.prototype.filterFalse = function (): Observable<void> {
    return this.pipe(filter(x => x ? false : true))
}

Observable.prototype.take = function <T>(n: number): Observable<T> {
    return this.pipe(take(n))
}

Observable.prototype.drop = function <T>(n: number): Observable<T> {
    return this.pipe(skip(n))
}

Observable.prototype.startWith = function <P>(value: P): Observable<P> {
    return this.pipe(startWith(value))
}