import { Node } from './fiat';
import { JsonMap } from './json';

export function requireThat(boolean: boolean, message: string | null = null) {
    if (!boolean) throw message || 'requirement not met'
}

export function arraysAreEqual(a: Array<any>, b: Array<any>): boolean {
    if (a == b) return true
    if (a.length != b.length) return false
    for (let i = 0, length = a.length; i < length; i++) {
        if (a[i] != b[i]) return false
    }
    return true
}

export function equals(a: any, b: any): boolean {
    if (a === b || a == b) return true
    if (isArray(a)) {
        if (!isArray(b) || b.length != a.length) return false
        for (let i = 0, length = a.length; i < length; i++) if (!equals(a[i], b[i])) return false
        return true
    } else if (a instanceof Date) {
        if (!(b instanceof Date)) return false
        return a.getTime() == b.getTime()
    } else if (isObject(a)) {
        if (!isObject(b)) return false
        for (let key in a) if (!(key in b) || !equals(a[key], b[key])) return false
        return true
    }
    return a == b
}

export function observe(node: Node, options: MutationObserverInit, onUpdate: () => void) {
    const observer = new MutationObserver((mutations, observer) => {
        observer.takeRecords()
        onUpdate()
    })
    node.attached().onUpdate(attached => {
        if (attached) observer.observe(node.element, options)
        else observer.disconnect()
        if (attached) onUpdate()
    })
}

export class IgnoreNext {

    private ignoreNext = false

    set() { this.ignoreNext = true }

    check(): boolean {
        const result = this.ignoreNext
        this.ignoreNext = false
        return result
    }

}


export const isArray = Array.isArray || function (object) {
    return object instanceof Array;
}

export function isObject(value) {
    return (typeof value == "object") && (value !== null)
}

export function isString(value) {
    return (typeof value == 'string' || value instanceof String)
}

export function isFunction(value) {
    return (typeof value == 'function')
}


export function sum<T>(array: T[], value: (element: T) => number): number {
    let sum = 0
    for (let element of array) sum += value(element)
    return sum
}

export namespace http {

    export function get(path: string): Promise<Response> {
        return fetch(path, {
            method: 'GET',
            cache: 'no-cache'
        })
    }

    export function getJson(path: string): Promise<JsonMap> {
        return fetch(path, { method: 'GET', cache: 'no-cache' }).then(answer => {
            if (answer.status != 200) throw (answer.statusText || "internal error")
            return answer.json()
        })
    }

    export function post(path: string, content: any): Promise<JsonMap> {
        return fetch(path, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify(content),
            headers: { 'content-type': 'application/json' }
        }).then(answer => {
            if (answer.status != 200) throw answer.statusText
            return answer.text().then(text => {
                return JSON.parse(text)
            })
        })
    }

    export function upload(path: string, content: Blob): Promise<Response> {
        return fetch(path, {
            method: 'POST',
            body: content
        })
    }

}
