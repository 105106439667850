import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


export namespace Reactive {

    export function subject<T>(initial: T): BehaviorSubject<T> {
        return new BehaviorSubject(initial)
    }

    export function or(...observables: Observable<boolean>[]): Observable<boolean> {
        const observable = combineLatest.apply(combineLatest, observables) as Observable<boolean[]>
        return observable.pipe(map(values => values.some(value => value)))
    }

    export function and(...observables: Observable<boolean>[]): Observable<boolean> {
        const observable = combineLatest.apply(combineLatest, observables) as Observable<boolean[]>
        return observable.pipe(map(values => values.every(value => value)))
    }

    export function not(observable: Observable<boolean>) { return observable.pipe(map(x => !x)) }

    export function toggle(subject: BehaviorSubject<boolean>) { subject.next(!subject.getValue()) }

}


export class DerivedActive {

    private currentActive: BehaviorSubject<boolean>

    constructor(private origin: BehaviorSubject<boolean>) {
        origin.subscribe(active => this.currentActive && this.currentActive.next(active))
    }

    get(): BehaviorSubject<boolean> {
        this.currentActive && this.currentActive.next(false)
        const newSubject = Reactive.subject(this.origin.getValue())
        this.currentActive = newSubject
        return newSubject
    }
}
